const  videojs = require('video.js').default;

// The actual plugin function is exported by this module, but it is also
// attached to the `Player.prototype`; so, there is no need to assign it
// to a variable.
require('videojs-contrib-quality-levels');
require('videojs-hls-quality-selector');

if (document.getElementById('video-player')) {
  var player = videojs('video-player');
  
  player.qualityLevels();
  player.hlsQualitySelector({
    displayCurrentQuality: true,
  });
}